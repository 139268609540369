import React, { useState } from 'react';
import Background from '../../images/company/company_bg.png';
import styled from 'styled-components';

const FormWrap = styled('div')`
  width: 800px;
  margin: 20px auto;
  > p {
    padding: 15px 0;
  }
  table {
    td {
      padding: 10px;
      font-size: 16px;
      input,
      textarea {
        display: block;
        width: 100%;
        padding: 5px;
        height: 40px;
        line-height: 40px;
        margin: 5px 0;
      }
      textarea {
        height: 400px;
      }
    }
  }
  div {
    position: relative;
    margin: 0 10px;
    label {
      display: block;
      position: relative;
      width: 100%;
      font-size: 14px;
      padding: 0 0 0 25px;
      z-index: 2;
    }
    input {
      display: none;
    }
    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 18px;
      height: 18px;
      border: 1px solid #ccc;
      z-index: -1;
    }
    input:checked + span:after {
      content: url(${require(`../../images/support/ic_check.png`).default});
      position: absolute;
      top: 0;
      left: 2px;
    }
  }
  form > input {
    display: block;
    width: 150px;
    margin: 20px auto;
    font-size: 14px;
    height: 45px;
    line-height: 45px;
    background-color: #1c8eb9;
    color: #fff;
  }
`;

export default function Contact() {
  const [formData, setFormData] = useState({});
  const [agreecheck, setAgreeCheck] = useState(false);
  const handleInput = (e) => {
    const copyFormData = { ...formData };
    copyFormData[e.target.name] = e.target.value;
    setFormData(copyFormData);
  };

  const sendData = async (e) => {
    e.preventDefault();
    if (agreecheck === false) {
      alert('개인정보 수집/이용에 동의는 필수사항 입니다.');
      return;
    } else {
      try {
        await fetch('https://v1.nocodeapi.com/gsdlabcorp/google_sheets/sVwjvAHgMfDZqNGU?tabId=sheet', {
          method: 'post',
          body: JSON.stringify([
            [formData.companyname, formData.name, formData.phone, formData.email, formData.message],
          ]),
          headers: {
            'Content-Type': 'application/json',
          },
        });
        alert('문의 남겨주셔서 감사합니다.\n빠른 시일 내에 답변 드리도록 하겠습니다.');
        window.location.reload();
      } catch (error) {}
    }
  };

  return (
    <>
      <div className="navTopBg" style={{ backgroundImage: `url(${Background})` }}>
        <p>문의하기</p>
      </div>
      <FormWrap>
        <p>* 필수항목 입니다.</p>
        <form className="input-form" id="contact" name="contact" onSubmit={sendData}>
          <table>
            <tr>
              <td>
                <span>회사이름</span>
                <input type="text" name="companyname" defaultValue="" onChange={handleInput} />
              </td>
              <td>
                <span>* 이름</span>
                <input type="text" name="name" defaultValue="" required onChange={handleInput} />
              </td>
            </tr>
            <tr>
              <td>
                <span>* 연락처</span>
                <input type="text" name="phone" defaultValue="" required onChange={handleInput} />
              </td>
              <td>
                <span>* 이메일</span>
                <input type="email" name="email" defaultValue="" required onChange={handleInput} />
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                * 문의내용
                <textarea name="message" required onChange={handleInput}></textarea>
              </td>
            </tr>
          </table>
          <div>
            <label htmlFor="infoCheck">개인정보 수집/이용에 동의합니다.</label>
            <input name="agree" type="checkbox" id="infoCheck" onClick={() => setAgreeCheck(true)}></input>
            <span></span>
          </div>
          <input name="submit" type="submit" value="보내기" />
        </form>
      </FormWrap>
    </>
  );
}

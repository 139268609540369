import React from 'react';
import './BannerItem.scss';

const BannerItem = ({ bannerInfo, slideIndex, returnTransform }) => {
  return (
    <div
      className={`BannerItem ${slideIndex === bannerInfo.id ? 'center' : ''}`}
      style={{
        transform: `translate3d(${returnTransform(bannerInfo.id)}%, 0px, 0px)`,
        transition: `all 450ms ease-out 0s`,
      }}
    >
      <div>
        <img src={bannerInfo.imgUrl} alt="slider-banner-img" />
        <div className="slider-banner-content">{bannerInfo.content}</div>
      </div>
    </div>
  );
};

export default BannerItem;

import React from 'react';
import { SliderBannerContainer } from '../../main/SliderBanner';
import { NavigatorBoxList } from '../../main/NavigatorBoxList';
import { TechList } from '../../main/TechList';

const PageTemplate = () => {
  return (
    <>
      <SliderBannerContainer />
      <NavigatorBoxList />
      <TechList />
    </>
  );
};

export default PageTemplate;

import React from 'react';
import './NavigatorBoxList.scss';
import { call, contact, shop } from '../../../images/main';

const NavigatorBoxList = () => {
  return (
    <div className="NavigatorBoxList">
      <div className="innerWrap">
        <div className="inner">
          <dl>
            <dt>
              AM 10:00 ~ PM 07:00 (토, 일, 공휴일 휴무)
              <h2>고객상담 02-597-9630</h2>
            </dt>
            <dd>
              <img src={call} alt="고객상담 아이콘" />
            </dd>
          </dl>
        </div>
        <div className="inner">
          <dl>
            <dt>
              서버 스토리지 스위치 구입하고 싶을때
              <h2>온라인몰</h2>
            </dt>
            <dd>
              <img src={shop} alt="온라인몰 아이콘" />
            </dd>
          </dl>
          <a href="https://smartstore.naver.com/gsd" rel="noopener noreferrer" target="_blank">
            &nbsp;
          </a>
        </div>
        <div className="inner">
          <dl>
            <dt>
              제품이나 솔루션 문의를 하고 싶을때
              <h2>문의하기</h2>
            </dt>
            <dd>
              <img src={contact} alt="고객상담 아이콘" />
            </dd>
          </dl>
          <a href="/contact">&nbsp;</a>
        </div>
      </div>
    </div>
  );
};

export default NavigatorBoxList;

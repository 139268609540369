import React from 'react';
import './BannerList.scss';
import BannerItem from '../BannerItem/BannerItem';

const BannerList = ({ bannerList, slideIndex, returnTransform }) => {
  return (
    <div className="BannerList">
      {bannerList.map((bannerInfo, i) => (
        <BannerItem key={i} bannerInfo={bannerInfo} slideIndex={slideIndex} returnTransform={returnTransform} />
      ))}
    </div>
  );
};

export default BannerList;

import { tech1, tech2, tech3, tech4, tech5, tech6 } from '../../images/main';

const techList = [
  {
    imgUrl: tech1,
    techTitle: 'CCTV 설계/설치',
    techDesc: [
      { text: '학교, 기업 등 CCTV를 설계 및 설치하여 드립니다.' },
      { text: '전문가의 컨설팅을 통해 설치 지원 및' },
      { text: '설계, 구축, 유지보수까지 만족스러운 서비스를 제공합니다.' },
    ],
  },
  {
    imgUrl: tech2,
    techTitle: '네트워크 장비 공급 및 유지관리',
    techDesc: [
      { text: '각종 스위치 및 무선 AP, 네트워크 장비를' },
      { text: '고객의 환경에 맞추어 최적의 솔루션으로 설계해드립니다.' },
      { text: '다양한 브랜드의 정품 장비를 한번에 구축 할 수 있습니다.' },
    ],
  },
  {
    imgUrl: tech3,
    techTitle: '서버 / PC 공급 및 유지관리',
    techDesc: [
      { text: '웹서버, DB서버, 워크스테이션  PC등' },
      { text: '각종 시스템을 구축합니다.' },
      { text: 'HP, IBM, DELL 뿐만 아니라 조립형 맞춤서버로' },
      { text: '타사대비 월등한 IT 경쟁력을 보장해드립니다.' },
    ],
  },
  {
    imgUrl: tech4,
    techTitle: '보안 장비 SW / HW 공급',
    techDesc: [
      { text: '최근 이슈 되고 있는 각종 바이러스, 해킹, 사내데이터유출과' },
      { text: '같은 보안문제로부터 보안SW 및 CCTV보안 시스템 등을' },
      { text: '구축해 드립니다.' },
    ],
  },
  {
    imgUrl: tech5,
    techTitle: '스토리지 공급 및 렌탈 서비스',
    techDesc: [
      { text: '스토리지에 대한 폭넓은 노하우로' },
      { text: '파일공유서버, 백업서버,데이터 저장을 위한' },
      { text: 'NAS, DAS, SAN등을 구축하고' },
      { text: '월,분기 단위 렌탈서비스도 제공하고 있습니다.' },
    ],
  },
  {
    imgUrl: tech6,
    techTitle: '각종 IT 시스템 컨설팅',
    techDesc: [
      { text: '보안, 네트워크, 서버, 스토리지, 스마트스쿨 구축,  문서관리,' },
      { text: 'ERP 시스템, 유지보수 등 다양한 IT이슈에 대한 상담을 통해' },
      { text: 'IT 솔루션을 제공해드립니다.' },
    ],
  },
];

export default techList;

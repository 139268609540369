import React, { Component } from 'react';
import { SliderBanner } from '.';
import banner from '../../../images/main/banner1.png';
import banner2 from '../../../images/main/banner2.png';
import banner3 from '../../../images/main/banner3.png';
import banner4 from '../../../images/main/banner4.png';
import banner5 from '../../../images/main/banner5.png';
import banner6 from '../../../images/main/banner6.png';
import banner7 from '../../../images/main/banner7.png';

import bannerLogo from '../../../images/main/banner-logo1.png';
import bannerLogo2 from '../../../images/main/banner-logo2.png';
import bannerLogo3 from '../../../images/main/banner-logo3.png';
import bannerLogo4 from '../../../images/main/banner-logo4.png';
import bannerLogo5 from '../../../images/main/banner-logo5.png';
import bannerLogo6 from '../../../images/main/banner-logo6.png';
import bannerLogo7 from '../../../images/main/banner-logo7.png';

class SliderBannerContainer extends Component {
  state = {
    bannerList: [
      {
        id: 1,
        imgUrl: banner,
        content: (
          <>
            <p className="slide_icon">
              <img src={bannerLogo} alt="banner-logo" />
            </p>
            <p className="mid_txt">
              <span>G</span>lobal <span>S</span>tandard <span>D</span>eveloment
              <br />
              Laboratory
            </p>
            <div className="btm_txt">
              지에스디랩은
              <span>
                정보시스템 운영 경험과 노하우, 최고 수준의 우수한 인적 자원을 바탕으로
                <br />
                IT 컨설팅에서부터 구축, 운영에 이르기까지 종합적인 IT 서비스를 제공
              </span>
              합니다.
            </div>
          </>
        ),
      },
      {
        id: 2,
        imgUrl: banner2,
        content: (
          <>
            <p className="slide_icon">
              <img src={bannerLogo2} alt="banner-logo" />
            </p>
            <p className="mid_txt">CCTV 설계/설치</p>
            <div className="btm_txt">
              기업,군부대,관공서 등<span>CCTV 및 VMS 시스템</span>
              을 설계 및 설치하여 드리며
              <br />
              전문가의 컨설팅을 통해 <span>설치 지원 및 설계, 구축, 유지보수</span>까지
              <br />
              만족스러운 서비스를 제공합니다.
            </div>
          </>
        ),
      },
      {
        id: 3,
        imgUrl: banner3,
        content: (
          <>
            <p className="slide_icon">
              <img src={bannerLogo3} alt="banner-logo" />
            </p>
            <p className="mid_txt">네트워크 장비 공급 및 유지관리</p>
            <div className="btm_txt">
              <span>각종 스위치 및 무선 AP, 네트워크 장비</span>
              를<br />
              고객의 환경에 맞추어 최적의 솔루션으로 설계해드립니다.
              <br />
              다양한 브랜드의 정품 장비를 한번에 구축 할 수 있습니다.
            </div>
          </>
        ),
      },
      {
        id: 4,
        imgUrl: banner4,
        content: (
          <>
            <p className="slide_icon">
              <img src={bannerLogo4} alt="banner-logo" />
            </p>
            <p className="mid_txt">서버/PC 구축 및 유지관리</p>
            <div className="btm_txt">
              <span>전산센터, 보안/방재센터, R&D센터 소규모 전산실</span>
              에서부터
              <br />
              <span>대규모 시스템 구축</span>에 필요한 <span>서버, 워크스테이션, PC</span>등을
              <br />
              제공합니다.<span>HP, IBM, DELL</span> 뿐만 아니라 <span>조립형 맞춤서버</span>로<br />
              타사대비 월등한 <span>IT경쟁력</span>을 보장해드립니다.
            </div>
          </>
        ),
      },
      {
        id: 5,
        imgUrl: banner5,
        content: (
          <>
            <p className="slide_icon">
              <img src={bannerLogo5} alt="banner-logo" />
            </p>
            <p className="mid_txt">보안 솔루션 구축</p>
            <div className="btm_txt">
              최근 이슈 되고 있는 각종 <span>바이러스, 해킹, 사내데이터유출</span>과 같은
              <br />
              <span>보안문제 해결을 위한 다양한 솔루션</span>을 제시해 드립니다.
            </div>
          </>
        ),
      },
      {
        id: 6,
        imgUrl: banner6,
        content: (
          <>
            <p className="slide_icon">
              <img src={bannerLogo6} alt="banner-logo" />
            </p>
            <p className="mid_txt">스토리지 공급 및 렌탈 서비스</p>
            <div className="btm_txt">
              스토리지에 대한 폭넓은 노하우로 <span>파일공유서버, 백업서버, 데이터 저장</span>을 위한
              <br />
              <span>NAS, DAS, SAN</span>등을 구축하고 <span>월,분기 단위 렌탈서비스</span>도 제공하고 있습니다.
            </div>
          </>
        ),
      },
      {
        id: 7,
        imgUrl: banner7,
        content: (
          <>
            <p className="slide_icon">
              <img src={bannerLogo7} alt="banner-logo" />
            </p>
            <p className="mid_txt">IT컨설팅 SI 업무</p>
            <div className="btm_txt">
              <span>
                보안, 네트워크, 서버, 스토리지, 스마트팩토리, 스마트시티
                <br />
                구축, 문서관리, ERP시스템, 유지보수
              </span>{' '}
              등 다양한 IT이슈에
              <br />
              대한 상담을 통해 <span>IT 솔루션</span>을 제공해 드립니다.
            </div>
          </>
        ),
      },
    ],
    totalIndex: 0,
    slideIndex: 1,
  };

  returnTransform = (id) => {
    const { slideIndex } = this.state;
    if (id === slideIndex) return 0;

    return id * 100 - slideIndex * 100;
  };

  onClickRightArrow = () => {
    const { slideIndex, totalIndex } = this.state;
    if (slideIndex === totalIndex) {
      this.setState({ slideIndex: 1 });
    } else {
      const nextIndex = slideIndex + 1;
      this.setState({ slideIndex: nextIndex });
    }
  };

  onClickMoveToIndex = (moveToIndex) => {
    this.setState({ slideIndex: moveToIndex });
  };

  onClickLeftArrow = () => {
    const { slideIndex, totalIndex } = this.state;
    if (slideIndex === 1) {
      this.setState({ slideIndex: totalIndex });
    } else {
      const nextIndex = slideIndex - 1;
      this.setState({ slideIndex: nextIndex });
    }
  };

  componentDidMount() {
    const { bannerList } = this.state;
    this.setState({
      totalIndex: bannerList.length,
    });
  }
  render() {
    const { bannerList, slideIndex } = this.state;
    return (
      <>
        <SliderBanner
          bannerList={bannerList}
          slideIndex={slideIndex}
          onClickRightArrow={this.onClickRightArrow}
          onClickLeftArrow={this.onClickLeftArrow}
          onClickMoveToIndex={this.onClickMoveToIndex}
          returnTransform={this.returnTransform}
        />
      </>
    );
  }
}

export default SliderBannerContainer;

import React from 'react';
import styled from 'styled-components';
import Background from '../../images/company/company_bg.png';

const Wrap = styled('div')`
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  img {
    display: block;
    margin: 20px 0;
  }
  h1 {
    font-size: 14px;
  }
`;

const Support = () => {
  return (
    <>
      <div className="navTopBg" style={{ backgroundImage: `url(${Background})` }}>
        <p>기술지원</p>
      </div>
      <div className="container">
        <Wrap>
          <img src={require(`../../images/support/service_1.png`).default} alt="서비스" />
          <img src={require(`../../images/support/service_2.png`).default} alt="서비스" />
        </Wrap>
      </div>
    </>
  );
};

export default Support;

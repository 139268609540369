import React from 'react';
import './TechList.scss';
import { techList } from '../../../contents/main';

const TechList = () => {
  return (
    <div className="tech-list">
      <ul className="tech-list-wrap">
        {techList.map((techItem, i) => (
          <li className="tech-item" key={i}>
            <a href="/#">
              <div className="Wrap">
                <div className="tech-thumbnail-wrap">
                  <div
                    className="tech-thumbnail"
                    style={{ backgroundImage: `url(${techItem.imgUrl})` }}
                    alt="thumbnail"
                  ></div>
                </div>
                <div className="tech-detail">
                  <h2 className="tech-title">{techItem.techTitle}</h2>

                  <div className="tech-desc">
                    {techItem.techDesc.map((techInfo, j) => (
                      <div key={j}>
                        <label>{techInfo.text}</label>
                        <br />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TechList;

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

import './Product.scss';
import Background from '../../images/company/company_bg.png';
import Server from './Server';
import Network from './Network';
import Storage from './Storage';
import Solution from './Solution';

const Product = ({ match }) => {
  const [nav, setNav] = useState([
    { link: '/product/server:0', add: '', component: Server, title: '서버' },
    {
      link: '/product/network:1',
      add: '',
      component: Network,
      title: '네트워크',
    },
    {
      link: '/product/storage:2',
      add: '',
      component: Storage,
      title: '스토리지',
    },
    {
      link: '/product/software:3',
      add: '',
      component: Solution,
      title: '솔루션',
    },
  ]);

  const onClick = (index) => {
    let liActive = document.getElementsByClassName('tabLi');
    const changeNav = [...nav];
    for (let i in nav) {
      liActive[i].classList.remove('active');
      nav[i]['add'] = '';
    }
    nav[index.index]['add'] = 'active';
    setNav(changeNav);
  };

  useEffect(() => {
    let liActive = document.getElementsByClassName('tabLi');
    liActive[Object.keys(match.params)[0]].classList.add('active');
  }, [match]);

  return (
    <>
      <Router>
        <div className="navTopBg" style={{ backgroundImage: `url(${Background})` }}>
          <p>제품소개</p>
        </div>
        <div className="container">
          <ul className="tabDesign productTab">
            {nav.map((value, index) => (
              <li>
                <Link to={value.link} className={'tabLi ' + value.add} onClick={() => onClick({ index })}>
                  {value.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        {nav.map((value, index) => (
          <Route exact path={value.link} component={value.component} />
        ))}
      </Router>
    </>
  );
};
export default Product;

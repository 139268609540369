import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import About from './About';
import History from './History';
import Member from './Member';
import Doc from './Doc';
import Map from './Map';

import Background from '../../images/company/company_bg.png';

const Company = ({ match }) => {
  const [nav, setNav] = useState([
    { link: '/company/about:0', add: '', component: About, title: '회사개요' },
    { link: '/company/history:1', add: '', component: History, title: '연혁' },
    { link: '/company/member:2', add: '', component: Member, title: '조직도' },
    { link: '/company/doc:3', add: '', component: Doc, title: '인증/특허' },
    { link: '/company/map:4', add: '', component: Map, title: '오시는길' },
  ]);

  const onClick = (index) => {
    let liActive = document.getElementsByClassName('tabLi');
    const changeNav = [...nav];
    for (let i in nav) {
      liActive[i].classList.remove('active');
      nav[i]['add'] = '';
    }
    nav[index.index]['add'] = 'active';
    setNav(changeNav);
  };

  useEffect(() => {
    let liActive = document.getElementsByClassName('tabLi');
    liActive[Object.keys(match.params)[0]].classList.add('active');
  }, [match]);

  return (
    <>
      <Router>
        <div className="navTopBg" style={{ backgroundImage: `url(${Background})` }}>
          <p>회사소개</p>
        </div>
        <div className="container">
          <ul className="tabDesign companyTab">
            {nav.map((value, index) => (
              <li>
                <Link to={value.link} className={'tabLi ' + value.add} onClick={() => onClick({ index })}>
                  {value.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        {nav.map((value) => (
          <Route exact path={value.link} component={value.component} />
        ))}
      </Router>
    </>
  );
};

export default Company;

import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

// const API_KEY = "AIzaSyD-H_5q5V_XtSaAiZndoV3IJjZbxQI3_zQ";
const initialConfig = {
  zoom: 16,
  center: { lat: 37.4770579, lng: 126.8796693 },
};

const AddressWrap = styled('div')`
  position: relative;
  width: 1200px;
  margin: 0 auto;
  padding: 40px 20px 40px 100px;
  h1 {
    font-size: 15px;
  }
  p {
    color: #666;
    margin: 5px 0;
  }
`;

const Map = () => {
  const mapContainerRef = useRef(null);

  useEffect(() => {
    const createMap = () => {
      const container = mapContainerRef.current;
      const options = {
        // 지도의 중심 세팅
        center: new window.kakao.maps.LatLng(initialConfig.center.lat, initialConfig.center.lng),
        // draggable: false,
        level: 2,
      };

      return new window.kakao.maps.Map(container, options);
    };

    const map = createMap();

    if (map) {
      const imageSize = new window.kakao.maps.Size(27, 43); // 마커이미지의 크기입니다

      // 마커의 이미지정보를 가지고 있는 마커이미지를 생성합니다
      const markerImage = new window.kakao.maps.MarkerImage(
        'https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png',
        imageSize,
      );
      const markerPosition = new window.kakao.maps.LatLng(initialConfig.center.lat, initialConfig.center.lng); // 마커가 표시될 위치입니다, 위도 / 경도

      // 마커를 생성합니다
      const marker = new window.kakao.maps.Marker({
        position: markerPosition,
        image: markerImage, // 마커이미지 설정
      });

      // 마커가 지도 위에 표시되도록 설정합니다
      marker.setMap(map);

      const moveLatLon = new window.kakao.maps.LatLng(initialConfig.center.lat, initialConfig.center.lng);

      map.setCenter(moveLatLon);
      map.panTo(moveLatLon);
    }
  }, []);

  return (
    <>
      <div style={{ height: '500px', width: '100%' }} ref={mapContainerRef} />
      <AddressWrap>
        <h1>주소</h1>
        <p>서울특별시 금천구 가산디지털2로 101, 한라원앤원타워 A동 1209호</p>
        + 대표번호 : 02-597-9630 / 팩스번호 : 02-597-9631
        <br />+ Email : gsdlab@gsdlab.co.kr
      </AddressWrap>
    </>
  );
};
export default Map;

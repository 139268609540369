import React from 'react';

const Solution = () => {
  return (
    <div className="productWrap">
      <div>
        <h1>관제 솔루션</h1>
        <dl>
          <dt>
            <img src={require(`../../images/product/solution_esm.png`).default} alt="" />
          </dt>
          <dd>
            <h2>ESM (Easy System Monitor)</h2>
            ESM은 sms와 mms를 포함한 통합 모니터링 솔루션으로 Server, Network, Storage 제품들을 한눈에 모니터링 합니다.
            사이트 마다 커스터마이징 지원으로 담당자의 업무를 덜어드리고 IT제품들의 사전 징후를 파악하여 장애를
            예방합니다.
          </dd>
        </dl>
      </div>
      {/*
            <div>
                <h1>스마트 서버</h1>
                <dl>
                    <dt><img src={require(`../../images/product/solution_server.png`).default} alt="" /></dt>
                    <dd>
                        <h2>SMART SERVER</h2>
                        국산 서버를 기획, 제작하여 공공 및 기업 시장에 제공<br/>
                        일반 Server 가 아닌, 자가 진단 가능한 Smart Server를 통해 더욱 안전하고 효율적인 시스템 사용이 가능하고 고객의 Needs에 맞도록 Customizing 가능한 세계 최초의 Flexible Smart Server.
                    </dd>
                </dl>
            </div>
             */}
      <div>
        <h1>System Integration</h1>
        <dl>
          <dt>
            <img
              style={{
                width: '100%',
              }}
              src={require(`../../images/product/solution_system.png`).default}
              alt=""
            />
          </dt>
          <dd>
            <h2>S.I</h2>
            사용자들의 요구에 따라 하드웨어·소프트웨어·네트워크 등 유형의 제품과 컨설팅·시스템 설계 및 유지보수 등 무형
            서비스 기술을 통합, 의뢰자의 전산 및 경영환경에 맞는 종합전산해결책을 제공하는 전문정보처리 시스템 사업을
            지원합니다.
          </dd>
        </dl>
      </div>
      <div>
        <h1>GVMS (CCTV 솔루션)</h1>
        <dl>
          <dt>
            <img style={{ maxWidth: '80%' }} src={require(`../../images/product/solution_gvms.png`).default} alt="" />
          </dt>
          <dd>
            <h2>지능형 영상감시</h2>
            GVMS는 지능형 영상감시 솔루션으로 GSDLAB의 VMS제품입니다. SW와 HW 혹은 Appliance로 제공이 가능하며 고객의
            규모, 예산, 요청에 따라 원하시는 모든 구성으로 최적의 지능형 영상 감시 시스템을 설계 및 제공하여 드립니다.
            GVMS는 국내 1위 보안업체인 S 사의 VMS알고리즘을 채용하여 최고의 보안 및 지능형 감시를 제공합니다. 컨설팅,
            설계, 운영, 유지보수까지 모두 GSDLAB에서 원스텝으로 제공하여 드리며 최고 수준의 보안을 제공하여 드립니다.
          </dd>
        </dl>
      </div>
    </div>
  );
};

export default Solution;

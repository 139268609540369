import React from 'react';
import './Footer.scss';
import FooterLogo from '../../../images/base/footer/footer-logo.png';
const Footer = () => {
  return (
    <div className="Footer">
      <div className="footer-wrap">
        <div className="footer-logo">
          <img src={FooterLogo} alt="footer-logo" />
        </div>
        <div className="footer-top-wrap">
          <a href="/userinfo">개인정보 취급방침</a>
        </div>
        <p>
          서울특별시 금천구 가산디지털2로 101, 한라원앤원타워 A동 1209호 (우)08505 (주) 지에스디랩 (GSDlab Co., Ltd)
        </p>
        <p>대표번호:02-597-9630 팩스번호:02-597-9631 e-메일:kim@gsdlab.co.kr</p>
        <p>개인정보보호책임자 유연미 Copyright ⓒ (주)지에스디랩 (GSDlab Co., Ltd) All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;

import React, { useState } from 'react';
import './App.scss';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { Footer } from './components/base/Footer';
import { Main } from './components/base/Main';
import Logo from './images/base/header/logo.png';
import Company from './components/Company/Company';
import Business from './components/Business/Business';
import Product from './components/Product/Product';
import Support from './components/Support/Support';
import Contact from './components/Contact/Contact';
import UserInfo from './components/Support/UserInfo';

function App() {
  const [nav] = useState([
    {
      title: '회사소개',
      link: '/company/about:0',
      component: Company,
      sub: [
        { link: '/company/about:0', component: Company, title: '회사개요' },
        { link: '/company/history:1', component: Company, title: '연혁' },
        { link: '/company/member:2', component: Company, title: '조직도' },
        { link: '/company/doc:3', component: Company, title: '인증/특허' },
        { link: '/company/map:4', component: Company, title: '오시는길' },
      ],
    },
    {
      title: '사업영역',
      link: '/business/history:0',
      component: Business,
      sub: [
        { link: '/business/history:0', component: Business, title: '사업이력' },
        { link: '/business/brand:1', component: Business, title: '취급브랜드' },
      ],
    },
    {
      title: '제품소개',
      link: '/product/server:0',
      component: Product,
      sub: [
        { link: '/product/server:0', component: Product, title: '서버' },
        { link: '/product/network:1', component: Product, title: '네트워크' },
        { link: '/product/storage:2', component: Product, title: '스토리지' },
        { link: '/product/software:3', component: Product, title: '솔루션' },
      ],
    },
    {
      title: '기술지원',
      link: '/support',
      component: Support,
      sub: null,
    },
    {
      title: '온라인몰',
      link: 'https://smartstore.naver.com/gsd',
      component: null,
      sub: null,
    },
    {
      title: '문의하기',
      link: '/contact',
      component: Contact,
      sub: [{ link: '/contact', component: Contact, title: '문의하기' }],
    },
  ]);

  return (
    <div className="App">
      <Router>
        <header>
          <div className="headerInner">
            <h1>
              <Link to="/">
                <img src={Logo} alt="logo" className="header-logo" />
              </Link>
            </h1>
          </div>
          <div className="navWrap">
            <div className="inner">
              <ul className="navlist">
                {nav.map((value, index) => (
                  <li className="navigator-item" key={index}>
                    {value.title !== '온라인몰' ? (
                      <Link to={value.link}>{value.title}</Link>
                    ) : (
                      <a href="https://smartstore.naver.com/gsd" rel="noopener noreferrer" target="_blank">
                        <span>온라인몰</span>
                      </a>
                    )}

                    {value.sub !== null && (
                      <div className="subnav">
                        <ul>
                          {value.sub.map((val, idx) => (
                            <li key={idx}>
                              <Link to={val.link}>
                                <span>{val.title}</span>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </header>
        <Route exact path="/" component={Main} />
        {nav.map(
          (value, index) =>
            value.sub !== null &&
            value.title !== '온라인몰' &&
            value.sub.map((val) => <Route exact path={val.link} component={val.component} />),
        )}
        <Route exact path="/support" component={Support} />
        <Route exact path="/userinfo" component={UserInfo} />
      </Router>
      <Footer />
    </div>
  );
}

export default App;

import React from 'react';
import styled from 'styled-components';

const Wrap = styled('div')`
  border: 1px solid #ccc;
  padding: 20px;
  text-align: center;
  h1 {
    font-size: 14px;
  }
`;

const Brand = () => {
  return (
    <Wrap>
      <img src={require(`../../images/business/brand.png`).default} alt="Brand" />
    </Wrap>
  );
};

export default Brand;

import React from 'react';
import styled from 'styled-components';

const Txt = styled('p')`
  font-size: 15px;
  text-align: center;
  line-height: 180%;
  font-weight: bold;
  span {
    color: #00abeb;
  }
`;

const About = () => {
  return (
    <>
      <div className="container text_center">
        <Txt>
          지에스디랩은
          <span>
            정보시스템 운영 경험과 노하우, 최고 수준의 인적 자원을 바탕으로
            <br />
            IT 컨설팅에서부터 구축, 운영에 이르기까지 종합적인 IT 서비스를 제공
          </span>
          합니다.
        </Txt>
        <p className="imgWrap">
          <img src={require(`../../images/company/about.png`).default} alt="조직도" />
        </p>
      </div>
    </>
  );
};

export default About;

import React from 'react';
import './SliderBanner.scss';
import { BannerList } from '../BannerList';
const SliderBanner = ({
  bannerList,
  slideIndex,
  onClickLeftArrow,
  onClickRightArrow,
  onClickMoveToIndex,
  returnTransform,
}) => {
  return (
    <div className="SliderBanner">
      <div className="slider-banner-wrap">
        <button className="slider-banner-icon slider-banner-left-arrow" onClick={onClickLeftArrow}>
          <svg
            className="image-gallery-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="6 0 12 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <polyline points="15 18 9 12 15 6"></polyline>
          </svg>
        </button>
        <button className="slider-banner-icon slider-banner-right-arrow" onClick={onClickRightArrow}>
          <svg
            className="image-gallery-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="6 0 12 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <polyline points="9 18 15 12 9 6"></polyline>
          </svg>
        </button>
        <div className="slider-banner-bullets">
          <div className="slider-banner-bullet-wrap">
            {bannerList.map((bannerInfo) => (
              <button
                className={`${bannerInfo.id === slideIndex ? 'active' : ''}`}
                key={bannerInfo.id}
                onClick={() => onClickMoveToIndex(bannerInfo.id)}
              />
            ))}
          </div>
        </div>
        <div className="slider-banner-swipe">
          <BannerList bannerList={bannerList} slideIndex={slideIndex} returnTransform={returnTransform} />
        </div>
      </div>
    </div>
  );
};

export default SliderBanner;

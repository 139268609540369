import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

import './Business.scss';
import History from './History';
import Brand from './Brand';

import Background from '../../images/company/company_bg.png';

const Business = ({ match }) => {
  const [nav, setNav] = useState([
    {
      link: '/business/history:0',
      add: '',
      component: History,
      title: '사업이력',
    },
    {
      link: '/business/brand:1',
      add: '',
      component: Brand,
      title: '취급 브랜드',
    },
  ]);

  const onClick = (index) => {
    let liActive = document.getElementsByClassName('tabLi');
    const changeNav = [...nav];
    for (let i in nav) {
      liActive[i].classList.remove('active');
      nav[i]['add'] = '';
    }
    nav[index.index]['add'] = 'active';
    setNav(changeNav);
  };

  useEffect(() => {
    let liActive = document.getElementsByClassName('tabLi');
    liActive[Object.keys(match.params)[0]].classList.add('active');
  }, [match]);

  return (
    <>
      <Router>
        <div className="navTopBg" style={{ backgroundImage: `url(${Background})` }}>
          <p>사업영역</p>
        </div>
        <div className="container">
          <ul className="tabDesign businessTab">
            {nav.map((value, index) => (
              <li>
                <Link to={value.link} className={'tabLi ' + value.add} onClick={() => onClick({ index })}>
                  {value.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        {nav.map((value, index) => (
          <Route exact path={value.link} component={value.component} />
        ))}
      </Router>
    </>
  );
};

export default Business;

import React from 'react';

const History = () => {
  return (
    <div className="container">
      <p className="imgWrap">
        <img src={require(`../../images/company/history.png`).default} alt="history" />
      </p>
    </div>
  );
};

export default History;

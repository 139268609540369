import React from 'react';

const Storage = () => {
  return (
    <div className="productWrap">
      <div>
        <h1>
          QNAP
          <span>
            <img src={require(`../../images/product/ic_qnap.png`).default} alt="QNAP" />
          </span>
        </h1>
        <dl>
          <dt>
            <img src={require(`../../images/product/storage_qnap.png`).default} alt="QNAP" />
          </dt>
          <dd>
            고객 지향적이고 미래를 대비한 기술이 모든 PowerEdge 서버에 적용되어 중소 기업 사무실에 알맞게 엔터프라이즈
            하이퍼스케일 환경에 이르는 탁월한 성능을 제공합니다.
          </dd>
        </dl>
        <div>
          <div style={{ width: '50%' }}>
            <h2>타워</h2>
            <ul className="twoLine">
              <li>TS-2888X-W2195-128G</li>
              <li>TS-963X-8G</li>
              <li>TS-2888X-W2133-64G</li>
              <li>TS-832X-8G</li>
              <li>TS-1277-1700-64G</li>
              <li>TS-653B-8G</li>
              <li>TVS-873e-8G</li>
              <li>TS-453B-8G</li>
              <li>TVS-673e-8G</li>
              <li>HS-453DX-8G</li>
              <li>TVS-473e-8G</li>
              <li>TS-451+-8G</li>
              <li>TVS-1282T3-i7-64G</li>
              <li>TS-251+-8G</li>
              <li>TVS-1282-i7-64G</li>
              <li>TS-351-4G</li>
              <li>TVS-882-i5-16G</li>
            </ul>
          </div>
          <div style={{ width: '50%' }}>
            <h2>랙</h2>
            <ul className="twoLine">
              <li>TS-2483XU-RP-E2136-16G</li>
              <li>TS-853BU-RP-8G</li>
              <li>TS-1683XU-RP-E2124-16G</li>
              <li>TS-453BU-RP-8G</li>
              <li>TS-883XU-RP-E2124-8G</li>
              <li>TS-873U-RP-16G</li>
              <li>TS-2477XU-RP-2700-16G</li>
              <li>TS-873U-RP-64G</li>
              <li>TS-1677XU-RP-2600-8G</li>
              <li>TS-1273U-RP-16G</li>
              <li>TS-1277XU-RP-2600-8G</li>
              <li>TS-1273U-RP-64G</li>
              <li>TS-877XU-RP-1200-4G</li>
              <li>TS-1673U-RP-16G</li>
              <li>TS-977XU-RP-2600-8G</li>
              <li>TS-1673U-RP-64G</li>
              <li>TS-1253BU-RP-8G</li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <h1>
          Infortrend
          <span>
            <img src={require(`../../images/product/ic_infortrend.png`).default} alt="Infortrend" />
          </span>
        </h1>
        <dl>
          <dt>
            <img src={require(`../../images/product/storage_infortrend.png`).default} alt="Infortrend" />
          </dt>
          <dd>
            EonStor DS는 기업을 위하여 디자인된 고가용성의 SAN 스토리지 솔루션입니다.
            <br />
            하드웨어 설계는 다양한 폼팩터와 유연한 호스트 보드로 선택의 범위를 넓혔고 안정적이고 신뢰도가 있는 모듈
            타입의 설계로 확장성이 뛰어납니다
          </dd>
        </dl>
        <div>
          <div style={{ width: '25%' }}>
            <h2>DS1000</h2>
            <ul>
              <li>DS 1012</li>
              <li>DS 1024B</li>
              <li>DS 1016</li>
              <li>DS 1024</li>
            </ul>
          </div>
          <div style={{ width: '25%' }}>
            <h2>DS2000</h2>
            <ul>
              <li>DS 2012</li>
              <li>DS 2024B</li>
              <li>DS 2016</li>
              <li>DS 2024</li>
            </ul>
          </div>
          <div style={{ width: '25%' }}>
            <h2>DS3000</h2>
            <ul>
              <li>DS 3012U</li>
              <li>DS 3024UB</li>
              <li>DS 3016U</li>
              <li>DS 3024U</li>
            </ul>
          </div>
          <div style={{ width: '25%' }}>
            <h2>DS4000</h2>
            <ul>
              <li>DS 4024B</li>
              <li>DS 4016</li>
              <li>DS 4024</li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <h1>
          Synology
          <span>
            <img src={require(`../../images/product/ic_synology.png`).default} alt="Synology" />
          </span>
        </h1>
        <dl>
          <dt>
            <img src={require(`../../images/product/storage_synology.png`).default} alt="Synology" />
          </dt>
          <dd>
            Synology는 저렴한 비용으로 데이터 저장을 중앙집중화하고 데이터 백업을 단순화하며 다양한 플랫폼에서 파일들을
            공유 및 동기화하고 이동 중에도 데이터에 접근할 수 있는 솔루션을 제공합니다.
          </dd>
        </dl>
        <div>
          <div style={{ width: '50%' }}>
            <h2>타워</h2>
            <ul className="threeLine">
              <li>DS420J</li>
              <li>DS1019+</li>
              <li>DS1819+</li>
              <li>DS418</li>
              <li>DS620slim</li>
              <li>DS2419+</li>
              <li>DS418PLAY</li>
              <li>DS1618+</li>
              <li>DS3018xs</li>
              <li>DS918+</li>
              <li>DS1817</li>
              <li>DS3617xs</li>
              <li>FS1018</li>
            </ul>
          </div>
          <div style={{ width: '50%' }}>
            <h2>랙</h2>
            <ul className="fourLine">
              <li>RS820+</li>
              <li>RS2418RP+</li>
              <li>RS3617RPxs</li>
              <li>RS4017xs+</li>
              <li>RS820RP+</li>
              <li>RS2818RP+</li>
              <li>RS3617xs+</li>
              <li>RC18015xs+</li>
              <li>RS1219+</li>
              <li>RS1619xs+</li>
              <li>SA3400</li>
              <li>FS3400</li>
              <li>RS2418+</li>
              <li>RS3618xs</li>
              <li>SA3200D</li>
              <li>FS6400</li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <h1>
          READYNAS
          <span>
            <img src={require(`../../images/product/ic_netgear.png`).default} alt="NETGEAR" />
          </span>
        </h1>
        <dl>
          <dt>
            <img src={require(`../../images/product/storage_readynas.png`).default} alt="NETGEAR" />
          </dt>
          <dd>
            중소기업의 IT 서버실에 맞는 최고의 네트워크 스토리지 플랫폼을 제공합니다.
            <br />
            Rackmount 스토리지 라인업은 우수한 5 레벨 데이터 보호, 일부 모델에 적용된 10기가비트 코퍼(Copper) 이더넷
            네트워크 인터페이스, 확장 시 최대 132 베이 용량 지원 등으로 TCO를 최소화하고 관리는 단순화하면서도 뛰어난
            성능, 다기능성, 확장성을 제공합니다.
          </dd>
        </dl>
        <div>
          <div style={{ width: '50%' }}>
            <h2>타워</h2>
            <ul className="threeLine">
              <li>RN628X</li>
              <li>RN524X</li>
              <li>RN422</li>
              <li>RN626X</li>
              <li>RN428</li>
              <li>RN214</li>
              <li>RN528X</li>
              <li>RN426</li>
              <li>RN212</li>
              <li>RN526X</li>
              <li>RN424</li>
            </ul>
          </div>
          <div style={{ width: '50%' }}>
            <h2>랙</h2>
            <ul className="threeLine">
              <li>RN2120</li>
              <li>RN3138</li>
              <li>RR4360X</li>
              <li>RR2304</li>
              <li>RR3312</li>
              <li>RR2312</li>
              <li>RR4312X</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Storage;

import React from 'react';

const Network = () => {
  return (
    <div className="productWrap">
      <div>
        <h1>
          DELL EMC
          <span>
            <img src={require(`../../images/product/ic_dell.png`).default} alt="dell" />
          </span>
        </h1>
        <dl>
          <dt>
            <img src={require(`../../images/product/network_dell.png`).default} alt="dell" />
          </dt>
          <dd>
            고객 지향적이고 미래를 대비한 기술이 모든 PowerEdge 서버에 적용되어 중소 기업 사무실에 알맞게 엔터프라이즈
            하이퍼스케일 환경에 이르는 탁월한 성능을 제공합니다.
          </dd>
        </dl>
        <div>
          <div style={{ width: '33.33%' }}>
            <h2>타워서버</h2>
            <ul>
              <li>PowerSwitch N1100 시리즈</li>
              <li>Dell Networking N1500 스위치</li>
              <li>Dell Networking N2000 시리즈</li>
              <li>PowerSwitch N3000 시리즈</li>
              <li>PowerSwitch N2200 시리즈</li>
            </ul>
          </div>
          <div style={{ width: '33.33%' }}>
            <h2>스마트 관리형스위치</h2>
            <ul>
              <li>Dell Networking X 시리즈</li>
            </ul>
          </div>
          <div style={{ width: '33.33%' }}>
            <h2>데이터 센터 스위치</h2>
            <ul>
              <li>PowerSwitch S 시리즈 25-100Gbe Gbe</li>
              <li>PowerSwitch S 시리즈 10Gbe</li>
              <li>PowerSwitch S 시리즈 1Gbe</li>
              <li>PowerSwitch Z 시리즈</li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <h1>
          Hwelett Packard Enterprise
          <span>
            <img src={require(`../../images/product/ic_hp.png`).default} alt="hp" />
          </span>
        </h1>
        <dl>
          <dt>
            <img src={require(`../../images/product/network_hp.png`).default} alt="hp" />
          </dt>
          <dd>데이터 센터, 캠퍼스, 지사 전반에서 에지부터 코어까지 아우르는 민첩하고 확장 가능한 스위치</dd>
        </dl>
        <div>
          <div style={{ width: '50%' }}>
            <h2>10/100/1000</h2>
            <ul className="twoLine">
              <li>Aruba 2530 24 PoE+</li>
              <li>Aruba 2930F 24G 4SFP</li>
              <li>Aruba 2530 24G</li>
              <li>Aruba 2930F 24G PoE+ 4SFP</li>
              <li>Aruba 2530 48G</li>
              <li>Aruba 2930F 48G 4SFP</li>
              <li>Aruba 2530 8G PoE+</li>
              <li>Aruba 2930F 48G PoE+ 4SFP</li>
              <li>Aruba 2530 8G</li>
              <li>Aruba 2930M 24G 1슬롯</li>
              <li>Aruba 2930M 48G 1슬롯</li>
            </ul>
          </div>
          <div style={{ width: '50%' }}>
            <h2>1Gbe(10G 업링크 포함)</h2>
            <ul className="twoLine">
              <li>Aruba 2540 24G 4SFP+</li>
              <li>Aruba 2930F 48G 4SFP+</li>
              <li>Aruba 2540 24G PoE+ 4SFP+</li>
              <li>Aruba 2930F 48G PoE+ 4SFP+</li>
              <li>Aruba 2540 48G 4SFP+</li>
              <li>Aruba 2930F 8G PoE+ 2SFP+</li>
              <li>Aruba 2540 48G PoE+ 4SFP+</li>
              <li>Aruba 2930M 24G PoE+ 1슬롯</li>
              <li>Aruba 2930F 24G 4SFP+</li>
              <li>Aruba 2930M 48G PoE+ 1슬롯</li>
              <li>Aruba 2930F 24G PoE+ 4SFP+</li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <h1>
          Lenovo
          <span>
            <img src={require(`../../images/product/ic_lenovo.png`).default} alt="Lenovo" />
          </span>
        </h1>
        <dl>
          <dt>
            <img src={require(`../../images/product/network_lenovo.png`).default} alt="Lenovo" />
          </dt>
          <dd>
            랙 스위치는 네트워크 전반에 걸쳐 비즈니스와 사용자에게 필요한 빠른 속도와 인텔리전스를 제공하며 대기 시간
            짧고 전력 사용량이 낮습니다.
          </dd>
        </dl>
        <div>
          <div>
            <ul className="threeLine">
              <li>ThinkSystem NE0152T RackSwitch</li>
              <li>ThinkSystem NE1072T RackSwitch</li>
              <li>Lenovo 랙 스위치 G8272</li>
              <li>ThinkSystem NE1032 RackSwitch</li>
              <li>ThinkSystem NE10032 RackSwitch</li>
              <li>Lenovo 랙 스위치 G7052</li>
              <li>ThinkSystem NE1032T RackSwitch</li>
              <li>ThinkSystem NE2572 RackSwitch</li>
              <li>랙 스위치 G7028</li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <h1>
          NETGEAR
          <span>
            <img src={require(`../../images/product/ic_netgear.png`).default} alt="NETGEAR" />
          </span>
        </h1>
        <dl>
          <dt>
            <img src={require(`../../images/product/server_netgear.png`).default} alt="NETGEAR" />
          </dt>
          <dd>
            NETGEAR는 빅 IT로 인한 비용과 복잡성 없이 소비자, 기업 및 서비스 제공업체에게 혁신적인 스위칭 솔루션을
            제공합니다.
            <br />
            중소기업은 NETGEAR의 언매니지드, 스마트 매니지드 플러스, 스마트 매니지드 프로 솔루션을 간편하게 설치해
            사용할 수 있습니다.
            <br />
            대기업과 캠퍼스 네트워크에서는 NETGEAR의 풀 매니지드 인프라를 사용하여 매우 유연한 코어, 분배 및 액세스
            레이어 전반에서 최종 사용자와 컨버지드 서비스, 서버 및 스토리지를 원활하게 연결할 수 있습니다.
          </dd>
        </dl>
        <div>
          <div style={{ width: '20%' }}>
            <h2>풀 매니지드 스위치</h2>
            <ul>
              <li>M4300 시리즈</li>
              <li>M4200 시리즈</li>
              <li>M4100 시리즈</li>
              <li>M6100 시리즈</li>
              <li>M5300 시리즈</li>
              <li>JGSM 시리즈</li>
            </ul>
          </div>
          <div style={{ width: '40%' }}>
            <h2>스마트 매니지드 프로 스위치</h2>
            <ul>
              <li>S350 스마트 매니지드 프로 스위치</li>
              <li>독립형 스마트 매니지드 프로 스위치 시리즈</li>
              <li>스마트 프로 클라우드</li>
              <li>멀티 기가비트 이더넷 스마트 매니지드 프로 스위치</li>
              <li>
                S3300 시리즈 - 10G RJ45 포트/광 포트 업링크
                <br />
                기가비트 스택형 스마트 매니지드 프로 스위치
              </li>
              <li>10기가비트 스마트 매니지드 프로 스위치 시리즈</li>
              <li>스택형 스마트 매니지드 프로 스위치 시리즈</li>
              <li>10G 업링크 스택형 스마트 매니지드 프로 스위치 시리즈</li>
            </ul>
          </div>
          <div style={{ width: '40%' }}>
            <h2>웹 매니지드 (플러스) 스위치</h2>
            <ul>
              <li>기가비트 스마트 매니지드 플러스 스위치 시리즈</li>
              <li>스마트 매니지드 플러스 클릭 스위치 제품군</li>
              <li>10기가비트/멀티 기가비트 스마트 매니지드 플러스 스위치 시리즈</li>
              <li>이지 마운트 스위치 제품군</li>
            </ul>
            <h2>웹 매니지드 (플러스) 스위치</h2>
            <ul>
              <li>기가비트 스마트 매니지드 플러스 스위치 시리즈</li>
              <li>스마트 매니지드 플러스 클릭 스위치 제품군</li>
              <li>10기가비트/멀티 기가비트 스마트 매니지드 플러스 스위치 시리즈</li>
              <li>이지 마운트 스위치 제품군</li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <h1>
          Supermicro
          <span>
            <img src={require(`../../images/product/ic_supermicro.png`).default} alt="Supermicro" />
          </span>
        </h1>
        <dl>
          <dt>
            <img src={require(`../../images/product/network_supermicro.png`).default} alt="Supermicro" />
          </dt>
          <dd>
            뛰어난 성능, 높은 전력 효율성 및 탁월한 가치를 제공하는 Supermicro의 네트워크 어댑터 는 대역폭을 최대화하고
            CPU 리소스를 오프로드하는 기능을 통해 네트워크 처리량 및 응용 프로그램 성능을 향상시킬 수 있습니다.
            <br />
            1U 폼 팩터는 배선 실 또는 랙 상단 환경에서 배치를 최적화 할 수있는 기능을 사용자에게 제공합니다.
            <br />
            포괄적 인 라우팅 및 프로토콜 소프트웨어 제품군은 가장 까다로운 엔터프라이즈 급 네트워킹 환경 에서도 최적의
            성능을 보장 합니다 .
          </dd>
        </dl>
        <div>
          <div style={{ width: '50%' }}>
            <h2>SSE-X3348S / SSE-X3348SR (54 포트)</h2>
            <ul>
              <li>48x ten-Gigabit Ethernet ports</li>
              <li>4x forty-Gigabit Ethernet ports</li>
              <li>2x one-Gigabit Ethernet ports</li>
              <li>Switching Capacity: 1284Gbps</li>
              <li>Wire-speed Layer 3 Routing</li>
              <li>1:1 Non-blocking connectivity</li>
              <li>Link Aggregation and Jumbo Frame Support</li>
              <li>1U form factor for flexible installation</li>
              <li>CLI and Web GUI Management Interfaces</li>
            </ul>
          </div>
          <div style={{ width: '50%' }}>
            <h2>SSE-X3348T / SSE-X3348TR (54 포트)</h2>
            <ul>
              <li>48x ten-Gigabit Ethernet ports (RJ45)</li>
              <li>4x forty-Gigabit Ethernet ports</li>
              <li>Switching Capacity: 1284Gbps</li>
              <li>Wire-speed Layer 3 Routing</li>
              <li>1:1 Non-blocking connectivity</li>
              <li>1U form factor for flexible installation</li>
              <li>Redundant hot-pluggable power supplies</li>
              <li>CLI and Web GUI Management Interfaces</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Network;

import React from 'react';

const Doc = () => {
  return (
    <>
      <div className="container">
        <p className="imgWrap">
          <img src={require(`../../images/company/doc_gsdlab.png`).default} alt="GSDLAB인증특허" />
        </p>
        <p
          style={{
            width: '823px',
            margin: '20px auto',
            borderTop: '1px solid #eee',
          }}
        />
        <p className="imgWrap">
          <img src={require(`../../images/company/doc_cm.png`).default} alt="CM인증특허" />
        </p>
      </div>
    </>
  );
};

export default Doc;

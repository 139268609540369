import React from 'react';

const Server = () => {
  return (
    <div className="productWrap">
      <div>
        <h1>
          DELL EMC
          <span>
            <img src={require(`../../images/product/ic_dell.png`).default} alt="dell" />
          </span>
        </h1>
        <dl>
          <dt>
            <img src={require(`../../images/product/server_dell.png`).default} alt="dell" />
          </dt>
          <dd>
            고객 지향적이고 미래를 대비한 기술이 모든 PowerEdge 서버에 적용되어 중소 기업 사무실에 알맞게 엔터프라이즈
            하이퍼스케일 환경에 이르는 탁월한 성능을 제공합니다.
          </dd>
        </dl>
        <div>
          <div style={{ width: '20%' }}>
            <h2>타워서버</h2>
            <ul>
              <li>PowerEdge T130</li>
              <li>PowerEdge T330</li>
              <li>PowerEdge T30</li>
              <li>PowerEdge T140</li>
              <li>PowerEdge T340</li>
              <li>PowerEdge T640</li>
              <li>PowerEdge T440</li>
              <li>PowerEdge T40</li>
            </ul>
          </div>
          <div style={{ width: '20%' }}>
            <h2>랙 서버</h2>
            <p>- 1소켓</p>
            <ul>
              <li>PowerEdge R6415</li>
              <li>PowerEdge R7415</li>
              <li>PowerEdge R230</li>
              <li>PowerEdge R330</li>
              <li>PowerEdge R240</li>
              <li>PowerEdge R6515</li>
              <li>PowerEdge R7515</li>
              <li>PowerEdge R340</li>
            </ul>
          </div>
          <div style={{ width: '40%' }}>
            <p>- 2소켓</p>
            <ul className="twoLine">
              <li>PowerEdge R740</li>
              <li>PowerEdge R630</li>
              <li>PowerEdge R740xd</li>
              <li>PowerEdge R730</li>
              <li>PowerEdge R640</li>
              <li>PowerEdge R740xd2</li>
              <li>PowerEdge C4130</li>
              <li>PowerEdge C6525</li>
              <li>PowerEdge R440</li>
              <li>PowerEdge R6525</li>
              <li>PowerEdge R540</li>
              <li>PowerEdge XR2</li>
              <li>PowerEdge R730xd</li>
              <li>PowerEdge R7525</li>
              <li>PowerEdge R7425</li>
              <li>PowerEdge RE2420</li>
            </ul>
          </div>
          <div style={{ width: '20%' }}>
            <p>- 4소켓</p>
            <ul>
              <li>PowerEdge R840</li>
              <li>PowerEdge R830</li>
              <li>PowerEdge R930</li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <h1>
          Hwelett Packard Enterprise
          <span>
            <img src={require(`../../images/product/ic_hp.png`).default} alt="hp" />
          </span>
        </h1>
        <dl>
          <dt>
            <img src={require(`../../images/product/server_hp.png`).default} alt="hp" />
          </dt>
          <dd>ProLiant 서버는 비즈니스 목표와 비즈니스 성장을 모두 지원하는 완벽한 인프라를 제공합니다.</dd>
        </dl>
        <div>
          <div style={{ width: '25%' }}>
            <h2>타워서버</h2>
            <ul>
              <li>HPE ProLiant ML110 Gen10</li>
              <li>HPE ProLiant ML350 Gen10</li>
              <li>HPE ProLiant ML30 Gen10</li>
            </ul>
          </div>
          <div style={{ width: '25%' }}>
            <h2>랙 서버</h2>
            <p>- 1소켓</p>
            <ul>
              <li>HPE ProLiant DL20 Gen10</li>
              <li>HPE ProLiant DL325 Gen10 Plus</li>
            </ul>
          </div>
          <div style={{ width: '25%' }}>
            <p>- 2소켓</p>
            <ul>
              <li>HPE Apollo 6500 Gen10</li>
              <li>HPE ProLiant DL160 Gen10</li>
              <li>HPE ProLiant DL180 Gen10</li>
              <li>HPE ProLiant DL360 Gen10</li>
              <li>HPE ProLiant DL380 Gen10</li>
              <li>HPE ProLiant DL385 Gen10 Plus</li>
              <li>HPE ProLiant XL170r Gen10</li>
              <li>HPE ProLiant XL190r Gen10</li>
              <li>HPE ProLiant XL230k Gen10</li>
              <li>HPE ProLiant XL450 Gen10</li>
            </ul>
          </div>
          <div style={{ width: '25%' }}>
            <p>- 4소켓</p>
            <ul>
              <li>HPE ProLiant DL560 Gen10</li>
              <li>HPE ProLiant DL580 Gen10</li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <h1>
          Lenovo
          <span>
            <img src={require(`../../images/product/ic_lenovo.png`).default} alt="Lenovo" />
          </span>
        </h1>
        <dl>
          <dt>
            <img src={require(`../../images/product/server_lenovo.png`).default} alt="Lenovo" />
          </dt>
          <dd>
            전 세계 비즈니스에 중요한 워크로드에 적합한 풍부한 기능의 플랫폼입니다.
            <br />
            업계 최고의 안정성, 전력 효율적 설계 및 세계적 수준의 성능을 제공합니다.
          </dd>
        </dl>
        <div>
          <div style={{ width: '35%' }}>
            <h2>타워서버</h2>
            <ul className="twoLine">
              <li>ThinkSystem ST50</li>
              <li>ThinkSystem ST550</li>
              <li>ThinkSystem ST250</li>
              <li>TS450</li>
            </ul>
          </div>
          <div style={{ width: '65%' }}>
            <h2>랙 서버</h2>
            <ul className="fourLine">
              <li>ThinkSystem SR670</li>
              <li>ThinkSystem SR650</li>
              <li>ThinkSystem SR630</li>
              <li>ThinkSystem SR570</li>
              <li>ThinkSystem SR655</li>
              <li>ThinkSystem SR635</li>
              <li>ThinkSystem SR590</li>
              <li>ThinkSystem SR550</li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <h1>
          Supermicro
          <span>
            <img src={require(`../../images/product/ic_supermicro.png`).default} alt="Supermicro" />
          </span>
        </h1>
        <dl>
          <dt>
            <img src={require(`../../images/product/server_supermicro.png`).default} alt="Supermicro" />
          </dt>
          <dd>
            고성능, 고효율 서버 기술 및 혁신의 글로벌 리더로서, 데이터 센터, 클라우드 컴퓨팅, 엔터프라이즈 IT, 빅 데이터
            HPC 및 임베디드 시장에 엔드 투 엔드 그린 컴퓨팅 솔루션을 개발하고 제공합니다.
          </dd>
        </dl>
      </div>
      <div>
        <h1>Customized Server / US-TS440G AGS(Auto Guidance System)</h1>
        <dl>
          <dt>
            <img src={require(`../../images/product/server_gss.png`).default} alt="Gss" />
          </dt>
          <dd>
            <p>
              <strong>US-TS440G 가이던스 서버</strong>
            </p>
            <span>
              2.8GHz 8C, 16GB, 2TB HDD*2ea(OS)(R1), 2TB HDD*4ea(Data)(R5), Dual PSU Win2016 Server with 5CAL / Data
              Migration / <strong>실시간 검사</strong> / <strong>재처리 공정의 일원화</strong>
            </span>
            <br />
            <br />
            <table>
              <thead>
                <tr>
                  <th width="30%" />
                  <th width="70%" />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <strong>Product Name</strong>
                  </td>
                  <td> UniverServer US-TS440G Tower Server</td>
                </tr>
                <tr>
                  <td>
                    <strong>Processor Type</strong>
                  </td>
                  <td> Intel® Xeon® Scalable Processor product family</td>
                </tr>
                <tr>
                  <td>
                    <strong>Memory Type</strong>
                  </td>
                  <td> 16 DIMM slots: DDR4 up to 3200MT/s</td>
                </tr>
                <tr>
                  <td>
                    <strong>ODD</strong>
                  </td>
                  <td> DVD-ROM</td>
                </tr>
                <tr>
                  <td>
                    <strong>I/O CARD</strong>
                  </td>
                  <td> Support for up to 6 full height slots</td>
                </tr>
                <tr>
                  <td>
                    <strong>Raid Controller</strong>
                  </td>
                  <td> PERC H755 RAID Adapter</td>
                </tr>
                <tr>
                  <td>
                    <strong>Network</strong>
                  </td>
                  <td> 2 x 1GbE LOMs</td>
                </tr>
                <tr>
                  <td>
                    <strong>Power</strong>
                  </td>
                  <td> 2 x 1400W Redundant Power supply</td>
                </tr>
                <tr>
                  <td>
                    <strong>Management</strong>
                  </td>
                  <td> Open Manager Integration Suite for MS Center / Vmware vCenter</td>
                </tr>
                <tr>
                  <td>
                    <strong>Processor</strong>
                  </td>
                  <td> Intel Xeon Silver 4309Y 2.8G, 8C/16T, 12M Cache</td>
                </tr>
                <tr>
                  <td>
                    <strong>Memory</strong>
                  </td>
                  <td> 16GB RDIMM, 3200MT/s, Dual Rank</td>
                </tr>
                <tr>
                  <td>
                    <strong>HDD</strong>
                  </td>
                  <td> 2 x 2,000GB 7.2K NL-SAS HDD for OS (Raid 1)</td>
                </tr>
                <tr>
                  <td>
                    <strong>HDD</strong>
                  </td>
                  <td> 4 x 2,000GB 7.2K NL-SAS HDD for Data (Raid 5)</td>
                </tr>
                <tr>
                  <td>
                    <strong>OS</strong>
                  </td>
                  <td> Windows 2016 Server 64bit With 5CAL</td>
                </tr>
                <tr>
                  <td>
                    <strong>설치 지원 서비스</strong>
                  </td>
                  <td> 가이던스 서버 Data Migration 및 Recorvery</td>
                </tr>
              </tbody>
            </table>
          </dd>
        </dl>
      </div>
      <div>
        <h1>Customized Server / CMR101 Migration Server (TOM SERVER)</h1>
        <dl>
          <dt>
            <img src={require(`../../images/product/server_cmr.png`).default} alt="Cmr" />
          </dt>
          <dd>
            <strong>CMR 101 1U Migration Server</strong>
            <br />
            <br />
            <table>
              <thead>
                <tr>
                  <th width="30%" />
                  <th width="70%" />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Form factor</td>
                  <td> 1U Rack Type Server</td>
                </tr>
                <tr>
                  <td>Processor</td>
                  <td> Intel Xeon E3-1230v6 3.5Ghz 4Core</td>
                </tr>
                <tr>
                  <td>Memory</td>
                  <td> 16GB DDR4 ECC UDIMM PC4-23400</td>
                </tr>
                <tr>
                  <td>SSD</td>
                  <td> 240GB SSD 2.5 inch</td>
                </tr>
                <tr>
                  <td>Ethernet</td>
                  <td> 2 x 1GbE RJ-45</td>
                </tr>
                <tr>
                  <td>Power</td>
                  <td> Single 350W Gold Level Power Supply</td>
                </tr>
                <tr>
                  <td>OS</td>
                  <td> Windows (Via Migration Tools)</td>
                </tr>
              </tbody>
            </table>
            <br />
            <p>
              <strong>Migration SW</strong>
            </p>
            <ul>
              <li>- IRTech Therm-O-Matic</li>
              <li>- CitectSCADA</li>
              <li>- TomServ</li>
              <li>- ScanView</li>
              <li>- Modbus 통신 지원</li>
              <li>- 적외선 Scanner Control Port</li>
            </ul>
          </dd>
        </dl>
      </div>
    </div>
  );
};

export default Server;

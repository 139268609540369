import React from 'react';

const Member = () => {
  return (
    <>
      <div className="container">
        <p className="imgWrap">
          <img src={require(`../../images/company/member.png`).default} alt="조직도" />
        </p>
      </div>
    </>
  );
};

export default Member;

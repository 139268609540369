import React, { useState } from 'react';
import styled from 'styled-components';

const Wrap = styled('div')`
  border: 1px solid #ccc;
  width: 810px;
  margin: 20px auto;
  padding: 20px;
  h1 {
    font-size: 16px;
    margin: 0 0 15px 0;
  }
  table {
    th {
      padding: 10px;
      font-size: 14px;
      border-bottom: 1px solid #00abeb;
    }
  }
  th,
  td {
    text-align: center;
    border-bottom: 1px solid #ccc;
    span {
      display: inline-block;
      width: 90px;
      word-break: break-word;
      line-height: 250%;
    }
    thead {
      th {
        border-bottom: 1px solid #00abeb;
      }
    }
    p {
      border-bottom: 1px solid #ccc;
      padding: 12px;
      &:last-child {
        border-bottom: none;
      }
    }
  }
`;

const History = () => {
  const [gsdhistoryList] = useState([
    {
      title: '2016년(54건) ~ 2017년(63건)',
      sub: [
        { title: '김해시청', txt: '시청 CCTV 구축 사업 전' },
        {
          title: '삼성 디스플레이 베트남',
          txt: '삼성 디스플레이 베트남 V3 지능형 감시 체계 구축 사업',
        },
        {
          title: '해상 광학연구소',
          txt: '해상광학연구소 지능형 VMS 구축 사업 ',
        },
        { title: '제니스 성형외과', txt: '방재실 구축 및 CCTV 구축 사업 건' },
        {
          title: '삼성토탈 전기실',
          txt: '지능형 감시 체계 및 출입통제 구축 사업 건',
        },
        { title: '경북과학기술원', txt: 'CCTV 및 출입통제 구축 사업 건' },
        {
          title: '삼성토탈(현, 한화토탈)',
          txt: '출입통제 및 지능형 감시 체계 구축 사업 건',
        },
        { title: '국방과학연구소', txt: '지능형 감시 체계 구축 사업 건' },
        { title: '삼성병원건', txt: 'CCTV 구축 사업 건' },
        { title: '남원국토유지청사', txt: '지능형 감시 체계 구축 사업 건' },
        { title: '삼성전자 광주', txt: '지능형 감시 체계 구축 사업 건' },
        { title: '군산 선유도', txt: '사무시설 CCTV 구축 사업 건' },
        { title: '대구 야구장', txt: '구장 내 CCTV 구축 사업 건' },
      ],
    },
    {
      title: '2018년(59건) ~ 2019년(870건)',
      sub: [
        {
          title: '수아사나 호텔',
          txt: '말레이시아 수아사나 호텔 및 상업시설 지능형 감시 체계 구축 건',
        },
        { title: '삼성 SDI', txt: '지능형 감시 체계 구축 사업 건' },
        {
          title: '이천 SK 연구소',
          txt: 'SK 연구동 주차동 사무동 지능형 시스템 구축 사업 건',
        },
        {
          title: '경남대학교',
          txt: '본관 상황실 구축 및 캠퍼스 지능형 감시 체계 구축 사업 건',
        },
        { title: '코레일유통', txt: '코레일 본점 CCTV 구축 사업 건' },
        {
          title: '대구경북 과학기술원',
          txt: '원내 지능형 감시 체계 구축 사업 건',
        },
        {
          title: '남원 국토유지청사',
          txt: '청사 주변 및 관리 시설 지능형 감시 체계 구축 사업 건',
        },
        {
          title: '동두천 발전소',
          txt: '발전소 내 CCTV 감시 시설 구축 사업 건',
        },
        { title: '나이키 스포츠', txt: '공장 내 VMS 시스템 구축 사업 건' },
        {
          title: '전주 국토관리사무소',
          txt: '터널 내, 지능형 기반 CCTV 구축 사업 건',
        },
        { title: '국방과학 연구소', txt: '부대 지능형 감시체계 구축 사업 건' },
        { title: '의정부 지방법원', txt: 'VMS 시스템 구축 사업 건' },
        {
          title: '신세계 스타필드',
          txt: '지능형 감시 체계 및 출입통제 구축 사업 건',
        },
      ],
    },
    {
      title: '2020년 ~ 현재(23건)',
      sub: [
        {
          title: '한국철도시설공단',
          txt: '국가 중요시설 지능형 감시체계 구축 사업 건',
        },
        { title: '공군', txt: '주기장 지능형 감시체계 구축 사업 건 1차' },
        {
          title: '방위사업청',
          txt: '육군본부 중요2차 시설 지능형 감시체계 사업 건',
        },
        { title: '포스코', txt: '여의도 파크원 지능형 감시체계 구축 사업 건' },
        {
          title: '세브란스 병원',
          txt: '세브란스 병원 지능형 감시체계 구축 사업 건',
        },
        {
          title: '신세계 스타필드',
          txt: '지능형 감시 체계 및 출입통제 구축 사업 건',
        },
        {
          title: '한국 가스공사',
          txt: '한국가스공사 삼척기지 VMS 시스템 지능형 업그레이드 사업 건',
        },
        { title: '한국 수력원자력', txt: '원전 내, 지능형 감시 체계 구축 건' },
        { title: '한국 도로공사', txt: '도로공사 CCTV 지능형 구축 사업 건' },
      ],
    },
  ]);

  const [cmhistoryList] = useState([
    {
      title: '공공부문',
      sub: [
        { title: '대한 송유관공사', txt: '시청 CCTV 구축 사업 전' },
        {
          title: '한국 가스공사',
          txt: '삼성 디스플레이 베트남 V3 지능형 감시 체계 구축 사업',
        },
        { title: '한국 은행', txt: '해상광학연구소 지능형 VMS 구축 사업 ' },
        { title: '한국 철도시설공단', txt: '방재실 구축 및 CCTV 구축 사업 건' },
        {
          title: '대구교정시설',
          txt: '지능형 감시 체계 및 출입통제 구축 사업 건',
        },
        { title: '한국 수력원자력', txt: 'CCTV 및 출입통제 구축 사업 건' },
        {
          title: '한국도로공사',
          txt: '출입통제 및 지능형 감시 체계 구축 사업 건',
        },
      ],
    },
    {
      title: '군부문',
      sub: [
        { title: '육군 00부대', txt: '중요시설 지능형 감시 체계 구축 사업 건' },
        { title: '공군 00부대', txt: '주기장 지능형 분석 시스템 도입 건' },
      ],
    },
    {
      title: '대기업',
      sub: [
        { title: '신한은행 데이터센터', txt: '센터 내 VMS 시스템 구축 건' },
        { title: '신세계 스타필드', txt: '지능형 감시 및 출입통제 구축 사업' },
        { title: '비씨카드 신사옥', txt: '신사옥 CCTV 구축 사업 건' },
        { title: '삼성 디스플레이', txt: 'V3 지능형 감시 체계 구축 사업 건' },
      ],
    },
  ]);
  return (
    <>
      <Wrap>
        <h1>관제사업(CCTV, VMS)</h1>
        <table>
          <colgroup>
            <col width="120"></col>
            <col width="250"></col>
            <col width="*"></col>
          </colgroup>
          <thead>
            <th>년도</th>
            <th>고객사</th>
            <th>프로젝트명</th>
          </thead>
          <tbody>
            {gsdhistoryList.map((value, index) => (
              <tr key={index}>
                <td>
                  <span>{value.title}</span>
                </td>
                <td>
                  {value.sub.map((val, idx) => (
                    <p>{val.title}</p>
                  ))}
                </td>
                <td>
                  {value.sub.map((val, idx) => (
                    <p>{val.txt}</p>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Wrap>
      <Wrap>
        <h1>컨설팅 참여 사업</h1>
        <table>
          <colgroup>
            <col width="120"></col>
            <col width="250"></col>
            <col width="*"></col>
          </colgroup>
          <thead>
            <th>년도</th>
            <th>고객사</th>
            <th>프로젝트명</th>
          </thead>
          <tbody>
            {cmhistoryList.map((value, index) => (
              <tr key={index}>
                <td>
                  <span>{value.title}</span>
                </td>
                <td>
                  {value.sub.map((val, idx) => (
                    <p>{val.title}</p>
                  ))}
                </td>
                <td>
                  {value.sub.map((val, idx) => (
                    <p>{val.txt}</p>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Wrap>
    </>
  );
};

export default History;
